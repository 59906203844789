import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Cta from '../components/cta'
import AltSection from '../components/columnAlternatingPartner'
import Showdown from 'showdown'

library.add(fat)

const Partners = ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()    

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white zero-bottom">
          <MDBContainer>
            <MDBRow>

            <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.openingpara,
                  }}
                />
                <div
                  className="mt-3 richtext divlink pb-5"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>             
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.tech &&
          <section className="bg-white zero-top">
            <MDBAnimation type="fadeIn" duration="1587ms">
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                  {post.frontmatter.tech.title && (
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium" >
                      {post.frontmatter.tech.title}               
                    </h2>
                    )}
                    {post.frontmatter.tech.description && (
                    <p className="text-medium">{post.frontmatter.tech.description}</p>
                  )}
                  </MDBCol>
               </MDBRow>
            </MDBContainer>
              <MDBContainer>
                  {post.frontmatter.tech.type.map((partnertype, index) => (
                    <>

                    {partnertype.title && (
                      <h3 className="font-alt font-w-700 pt-5 pb-5 pb-md-3 letter-spacing-1 title-xs-medium title-medium" >
                        {partnertype.title}
                      </h3>
                    )}
                    {partnertype.subtitle && (
                        <div
                        className="font-w-400 text-medium divlink pb-5 pr-lg-3"
                        dangerouslySetInnerHTML={{ __html: converter.makeHtml(partnertype.subtitle) }}
                        />            
                    )}
                    
                    {partnertype.partner.map((partner, index) => (
                      <AltSection
                      key={index}
                      textCol={'9'}
                      imageCol={'3'}
                      title={partner.title}
                      description={partner.description}
                      image={partner.image.childImageSharp.gatsbyImageData}
                      imageClass="rounded"
                      alt={partner.alttext}
                      placement={partner.placement}
                    />
                    ))}
                    </>
                  )                 
                  )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        }    

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </main>
    </Layout>
  )
}
export default Partners 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "Partners" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        ctatext
        ctaslug
        openingpara 
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        tech {
          title
          description
          type {
            title
            subtitle
            partner {
              placement
              description
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 90)
                }
              }
              alttext
            }
          }
        }    
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`